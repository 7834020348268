import { subHours } from 'date-fns';
import LogRocket from 'logrocket';
import Script from 'next/script';
import { useContext } from 'react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';
import useSWR from 'swr';

import APIMethodKeys from '../../../../client/APIMethodKeys';
import LINKS from '../../../../configs/links';
import { capitalizeFirstLetter } from '../../../../utils';
import { showChat } from '../../../../utils/liveChat';
import { storage_keys } from '../../../../utils/storage';
import { isFreePlan } from '../../../../utils/subscription';
import Avatar from '../../../common/base/Avatar';
import Button, { ClickableArea } from '../../../common/base/Button';
import { StyledCard, StyledCardSection } from '../../../common/base/Card';
import Divider from '../../../common/base/Divider';
import Icon from '../../../common/base/Icon';
import ProgressBar from '../../../common/base/ProgressBar';
import Text from '../../../common/base/Text';
import Tooltip from '../../../common/base/Tooltip';
import LineChart from '../../../common/Chart/LineChart';
import Dropdown from '../../../common/Dropdown';
import { Div } from '../../../common/helpers/StyledUtils';
import { ChartData, useMetric } from '../../../common/metrics/useMetric';
import { GlobalContext } from '../../../contexts/GlobalContext';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { useMemberTrial } from '../../../hooks/useMemberTrial';
import { DashboardContext } from '../DashboardContext';
import { StyledDashboardNavSection, StyledNavLinks, StyledPrimaryNavLink } from './';

import type HookdeckAPI from '../../../../client/Hookdeck';
export const handleLogout = (HookdeckAPI: HookdeckAPI) => {
  return HookdeckAPI.session.logout().then((response) => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (response?.redirect_url) {
      window.location.replace(response?.redirect_url);
    } else {
      window.location.replace('/signin');
    }
  });
};

export const StyledBeamerButton = styled(ClickableArea)<{ opened?: boolean }>(
  ({ theme, opened }) => css`
    border: none;

    ${opened &&
    css`
      background-color: ${theme.colors.surface.base.variant_surface};
    `}

    &:hover {
      opacity: 1 !important;
    }
    &:focus-visible {
      &:after {
        content: none;
      }
    }
    .beamer_icon.active {
      box-sizing: border-box;
      font-family: inherit;
      position: absolute;
      line-height: unset;
      text-transform: capitalize;
      border-radius: ${theme.radius.small};
      font-weight: ${theme.font_weigths.medium};
      width: unset;
      min-width: ${theme.pxToRem(20)};
      height: ${theme.pxToRem(20)};
      line-height: ${theme.pxToRem(16)};
      font-size: ${theme.pxToRem(12)};
      font-weight: ${theme.font_weigths.medium};
      padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};
      margin-left: auto;
      color: ${theme.colors.on.hue_container.primary};
      background-color: ${theme.colors.surface.container.primary} !important;
      z-index: 1000;
      top: ${theme.spacing(1.5)};
      right: ${theme.spacing(1.5)};

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: ${theme.radius.small};
        border: 1px solid ${theme.colors.outline.primary};
      }
    }
  `,
);

const TrialDaysProgressBar = styled(ProgressBar)<{ total: number }>(
  ({ theme, value, total }) => css`
    height: 4px;
    margin-top: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
    &::before {
      width: ${(value / total) * 100}%;
      background-color: ${theme.colors.on.neutral.primary_neutral};
    }
  `,
);

const MemberTrialCard = ({ dismissCard }: { dismissCard: () => void }) => {
  const { subscription, organization, organization_role } = useContext(DashboardContext);
  const { HookdeckAPI } = useContext(GlobalContext);

  const { is_member_trial_active, is_member_trial_expired, member_trial_left_days } =
    useMemberTrial(subscription);
  const { data: members } = useSWR(APIMethodKeys.organizations.listMembers(organization!.id), () =>
    HookdeckAPI.organizations.listMembers(),
  );
  const exceeds_users =
    subscription!.max_users && members && members.length >= subscription!.max_users;
  const invite_to_trial = !!exceeds_users && !organization?.workos_connection_id;

  if (is_member_trial_expired && ['admin', 'owner'].includes(organization_role!)) {
    return (
      <StyledCard m={2} style={{ width: 'auto' }}>
        <StyledCardSection p={3}>
          <Div flex={{ align: 'center', justify: 'space-between' }}>
            <Text subtitle>Member trial expired</Text>
            <Button minimal small p={0.5} icon="close" muted onClick={dismissCard}></Button>
          </Div>
          <Text muted>Upgrade to restore access to members added during the trial period.</Text>
          <Button
            to={(location) => ({
              ...location,
              pathname: `/settings/organization/plans`,
              state: { scroll: false },
            })}
            block
            primary
            outline
            m={{ t: 3 }}>
            Upgrade plan
          </Button>
        </StyledCardSection>
      </StyledCard>
    );
  } else if (is_member_trial_active && ['admin', 'owner'].includes(organization_role!)) {
    return (
      <StyledCard m={2} style={{ width: 'auto' }}>
        <StyledCardSection p={3}>
          <Div flex={{ align: 'center', justify: 'space-between' }}>
            <Div flex={{ align: 'center' }}>
              <Text subtitle>Free member trial</Text>
              <Tooltip tooltip="Invite unlimited members to this organization for a 14-day free trial period. Upgrade your plan to keep members on this organization indefinitely.">
                <Icon icon="info" muted m={{ l: 1 }} />
              </Tooltip>
            </Div>
            <Button minimal small p={0.5} icon="close" muted onClick={dismissCard}></Button>
          </Div>
          <TrialDaysProgressBar
            value={14 - (member_trial_left_days as number)}
            total={14}
            cancelled={false}
          />
          <Text muted>{14 - (member_trial_left_days as number)}/14 days used</Text>
          <Button
            to={(location) => ({
              ...location,
              pathname: `/settings/organization/members`,
              state: { scroll: false },
            })}
            block
            outline
            m={{ t: 3 }}>
            Invite
          </Button>
        </StyledCardSection>
      </StyledCard>
    );
  } else if (is_member_trial_active && !['admin', 'owner'].includes(organization_role!)) {
    return (
      <StyledCard m={2} style={{ width: 'auto' }}>
        <StyledCardSection p={3}>
          <Div flex={{ align: 'center', justify: 'space-between' }}>
            <Div flex={{ align: 'center' }}>
              <Text subtitle>Free trial access</Text>
              <Tooltip tooltip="You can only access this organization for a limited time. In order to maintain your access beyond the trial period, the organization owner must upgrade your plan.">
                <Icon icon="info" muted m={{ l: 1 }} />
              </Tooltip>
            </Div>
            <Button minimal small p={0.5} icon="close" muted onClick={dismissCard}></Button>
          </Div>
          <TrialDaysProgressBar
            value={14 - (member_trial_left_days as number)}
            total={14}
            cancelled={false}
          />
          <Text muted>{14 - (member_trial_left_days as number)}/14 days used</Text>
        </StyledCardSection>
      </StyledCard>
    );
  } else if (invite_to_trial && ['admin', 'owner'].includes(organization_role!)) {
    return (
      <StyledCard m={2} style={{ width: 'auto' }}>
        <StyledCardSection p={3}>
          <Div flex={{ align: 'center', justify: 'space-between' }}>
            <Div flex={{ align: 'center' }}>
              <Text subtitle>Free member trial</Text>
              <Tooltip tooltip="Invite unlimited members to this organization for a 14-day free trial period. Upgrade your plan to keep members on this organization indefinitely.">
                <Icon icon="info" muted m={{ l: 1 }} />
              </Tooltip>
            </Div>
            <Button minimal small p={0.5} icon="close" muted onClick={dismissCard}></Button>
          </Div>
          <Text muted>Unlock 14-day access.</Text>
          <Button
            to={(location) => ({
              ...location,
              pathname: `/settings/organization/members/add`,
              state: { scroll: false },
            })}
            block
            outline
            m={{ t: 3 }}>
            Invite
          </Button>
        </StyledCardSection>
      </StyledCard>
    );
  }

  return null;
};

const NavFooter = () => {
  const {
    user,
    subscription,
    organization,
    team,
    show_get_started,
    setShowGetStarted,
    organization_role,
  } = useContext(DashboardContext);
  const { HookdeckAPI } = useContext(GlobalContext);
  const { is_member_trial_active, is_member_trial_expired } = useMemberTrial(subscription);
  const [hide_member_trial_modal, setHideMemberTrialModal] = useLocalStorage<boolean>(
    `${storage_keys.hide_member_trial_modal}:${user?.id}:${user?.last_team_id}`,
    false,
  );
  const { data: members } = useSWR(APIMethodKeys.organizations.listMembers(organization!.id), () =>
    HookdeckAPI.organizations.listMembers(),
  );
  const exceeds_users =
    subscription!.max_users && members && members.length >= subscription!.max_users;
  const invite_to_trial = !!exceeds_users && !organization?.workos_connection_id;

  const theme = useTheme();
  const history = useHistory();

  const is_member_trial_rendering =
    !hide_member_trial_modal &&
    ((is_member_trial_expired && ['admin', 'owner'].includes(organization_role!)) ||
      (is_member_trial_active && ['admin', 'owner'].includes(organization_role!)) ||
      (is_member_trial_active && !['admin', 'owner'].includes(organization_role!)) ||
      (invite_to_trial && ['admin', 'owner'].includes(organization_role!)));

  const max_events = useMetric<ChartData>('chart:line', 'events_max_rate', 0, {
    date_range: {
      min: subHours(new Date(), 6).toISOString(),
      max: new Date().toISOString(),
    },
  });

  let throughput_status: 'ok' | 'warn' | 'danger' | null = null;
  if (max_events.data && max_events.data.data) {
    const max_event = Math.max(...max_events.data.data.map((d) => d.y as number));
    if (
      team!.max_events_per_second <
      (max_events.data.data[max_events.data.data.length - 1].y as number)
    ) {
      throughput_status = 'danger';
    } else if (max_event > team!.max_events_per_second) {
      throughput_status = 'warn';
    } else {
      throughput_status = 'ok';
    }
  }
  const memberTrialModal = <MemberTrialCard dismissCard={() => setHideMemberTrialModal(true)} />;

  return (
    <StyledDashboardNavSection p={{ x: 2, t: 4, b: 2 }}>
      {is_member_trial_rendering && memberTrialModal}
      <StyledNavLinks>
        {subscription?.plan &&
          isFreePlan(subscription.plan) &&
          !organization?.workos_directory_id &&
          !is_member_trial_rendering && (
            <StyledPrimaryNavLink to={`/settings/organization/plans`}>
              <Icon left icon="upgrade" pointer />
              <Text subtitle size="m">
                Upgrade Plan
              </Text>
            </StyledPrimaryNavLink>
          )}
        <NavLink to="/settings">
          <Icon left icon="settings" pointer />
          <Text subtitle size="m">
            Settings
          </Text>
        </NavLink>
        <Dropdown
          placement="right-end"
          block
          p={0}
          m={{ l: 2 }}
          onToggle={(opened) => {
            if (opened) {
              LogRocket.track('Clicked on Project Throughput', {
                throughput_status: throughput_status || undefined,
              });
              HookdeckAPI.track.event('Clicked on Project Throughput', { throughput_status });
            }
          }}
          renderToggle={(opened, toggle) => (
            <StyledNavLinks>
              <ClickableArea
                highlight={opened}
                on="background"
                flex={{ align: 'center' }}
                onClick={() => toggle(!opened)}>
                <Div style={{ position: 'relative' }}>
                  <Icon icon="throughput" muted pointer />
                </Div>
                <Text
                  m={{ l: 2 }}
                  subtitle
                  size="m"
                  w={100}
                  flex={{ justify: 'space-between', align: 'center' }}>
                  Project Throughput
                </Text>
                <div>
                  {throughput_status === 'warn' && <Icon icon="warning" warning pointer />}
                  {throughput_status === 'danger' && <Icon icon="error" danger pointer />}
                </div>
              </ClickableArea>
            </StyledNavLinks>
          )}>
          {(toggle) => (
            <>
              <StyledCardSection w={{ px: 480 }}>
                <StyledCardSection p={4} flex={{ gap: 2 }}>
                  <Text>
                    {throughput_status === 'ok' &&
                      'Your project is processing new events within your throughput threshold.'}
                    {throughput_status === 'warn' &&
                      'Your project has exceeded the throughput of new events within the last 6 hours.'}
                    {throughput_status === 'danger' &&
                      'Your project is currently exceededing the throughput of new events'}
                  </Text>
                </StyledCardSection>
                <LineChart
                  compact
                  height={168}
                  loading={!max_events}
                  datasets={
                    max_events && max_events.data
                      ? [
                          {
                            key: 'delivered',
                            label: 'Max new events per second',
                            theme_color: 'primary' as const,
                            monospace: false,
                            data: max_events.data.data,
                            total: max_events.data.total,
                            highest: max_events.data.highest,
                            average: max_events.data.average,
                            getDataLabel: (v: number) => `${v} / second`,
                            metric_type: 'max' as const,
                          },
                          {
                            key: 'limit',
                            label: 'Throughput limit',
                            theme_color: 'danger' as const,
                            monospace: false,
                            dashed: true,
                            data: max_events.data.data.map((d) => ({
                              ...d,
                              y: team!.max_events_per_second,
                            })),
                            // Placeholder values not actually used for anything
                            total: 0,
                            highest: 0,
                            average: 0,
                            getDataLabel: (v: number) => `${v} / second`,
                            metric_type: 'rate' as const,
                          },
                        ]
                      : []
                  }
                />
              </StyledCardSection>
              <StyledCardSection p={{ x: 4, y: 3 }} flex={{ justify: 'flex-end' }}>
                <Button
                  outline
                  icon="link"
                  to="/settings/project/quotas"
                  onClick={() => toggle(false)}>
                  View Throughput
                </Button>
              </StyledCardSection>
            </>
          )}
        </Dropdown>
        {process.env.BEAMER_ID && (
          <>
            <Script>
              {`var beamer_config = {
            product_id: "${process.env.BEAMER_ID}",
            button: false,
            bounce: false,
            display: "popup",
            lazy: false,
            user_id: "${user?.id}",
            user_email: "${user?.email}",
            user_created_at: "${user?.created_at}",
            user_firstname: "${user?.name}",
          };`}
            </Script>
            <Script src="https://app.getbeamer.com/js/beamer-embed.js" />
          </>
        )}
        <Dropdown
          placement="right-end"
          p={0}
          m={{ l: 2 }}
          parent_width={{ min: 225 }}
          renderToggle={(opened, toggle) => (
            <StyledNavLinks>
              <StyledBeamerButton
                highlight={opened}
                opened={opened}
                on="background"
                className="beamerTrigger"
                data-beamer-click="false"
                flex={{ align: 'center', justify: 'space-between' }}
                onClick={() => toggle(!opened)}>
                <Div flex={{ align: 'center' }}>
                  <Avatar name={user!.name} small neutral m={{ r: 2 }} />
                  <Text subtitle ellipsis>
                    {capitalizeFirstLetter(user!.name)}
                  </Text>
                </Div>
                <Icon
                  icon="horizontal_more"
                  pointer
                  style={{
                    position: 'absolute',
                    zIndex: 0,
                    right: theme.spacing(1.5),
                  }}
                />
              </StyledBeamerButton>
            </StyledNavLinks>
          )}>
          {(toggle) => (
            <>
              <Div p={3}>
                <Text ellipsis subtitle>
                  {user!.name}
                </Text>
                <Text ellipsis subtitle muted>
                  {user!.email}
                </Text>
              </Div>
              <Divider />
              <Div p={1}>
                <ClickableArea
                  to="/settings/account/profile"
                  onClick={() => toggle(false)}
                  p={{ y: 1.5, x: 2 }}
                  rounded
                  flex={{ align: 'center' }}>
                  <Icon icon="settings" left pointer />
                  <Text subtitle>Account Settings</Text>
                </ClickableArea>
                <Dropdown
                  placement="right-start"
                  block
                  p={0}
                  m={{ l: 2 }}
                  parent_width={{ min: 225 }}
                  renderToggle={(opened, toggle) => (
                    <StyledNavLinks>
                      <ClickableArea
                        rounded
                        flex={{ align: 'center', justify: 'space-between' }}
                        p={{ y: 1.5, x: 2 }}
                        onClick={() => toggle(!opened)}>
                        <Div flex={{ align: 'center' }}>
                          <Icon
                            icon={
                              theme.selected === 'system'
                                ? 'computer'
                                : theme.selected === 'light'
                                  ? 'light_mode'
                                  : 'dark_mode'
                            }
                            left
                          />
                          <Text subtitle>Theme</Text>
                        </Div>
                        <Icon icon="chevron_right" pointer />
                      </ClickableArea>
                    </StyledNavLinks>
                  )}>
                  {(toggle) => (
                    <Div p={1}>
                      <ClickableArea
                        p={{ y: 1.5, x: 2 }}
                        flex={{ align: 'center', justify: 'space-between' }}
                        rounded
                        onClick={() => {
                          toggle(false);
                          theme.toggleTheme('light');
                        }}>
                        <Div flex={{ align: 'center' }}>
                          <Icon icon="light_mode" left dark pointer />
                          <Text subtitle size="m">
                            Light
                          </Text>
                        </Div>
                        {theme.selected === 'light' && <Icon icon="success" pointer primary />}
                      </ClickableArea>
                      <ClickableArea
                        p={{ y: 1.5, x: 2 }}
                        flex={{ align: 'center', justify: 'space-between' }}
                        rounded
                        onClick={() => {
                          toggle(false);
                          theme.toggleTheme('dark');
                        }}>
                        <Div flex={{ align: 'center' }}>
                          <Icon icon="dark_mode" left dark pointer />
                          <Text subtitle size="m">
                            Dark
                          </Text>
                        </Div>
                        {theme.selected === 'dark' && <Icon icon="success" pointer primary />}
                      </ClickableArea>
                      <ClickableArea
                        p={{ y: 1.5, x: 2 }}
                        flex={{ align: 'center', justify: 'space-between' }}
                        rounded
                        onClick={() => {
                          toggle(false);
                          theme.toggleTheme('system');
                        }}>
                        <Div flex={{ align: 'center' }}>
                          <Icon icon="computer" left dark pointer />
                          <Text subtitle size="m">
                            System
                          </Text>
                        </Div>
                        {theme.selected === 'system' && <Icon icon="success" pointer primary />}
                      </ClickableArea>
                    </Div>
                  )}
                </Dropdown>
              </Div>
              <Divider />
              <Div p={1}>
                {!show_get_started && (
                  <ClickableArea
                    p={{ x: 2, y: 1 }}
                    flex={{ align: 'center' }}
                    rounded
                    onClick={() => {
                      history.push('/get-started');
                      setShowGetStarted(true);
                      toggle(false);
                    }}>
                    <Icon icon="grid_view" left dark pointer />
                    <Text subtitle size="m">
                      Get Started
                    </Text>
                  </ClickableArea>
                )}
                <ClickableArea
                  p={{ y: 1.5, x: 2 }}
                  as="a"
                  href={LINKS.docs}
                  target="_blank"
                  rel="noreferrer"
                  flex={{ align: 'center' }}
                  rounded>
                  <Icon icon="docs" left dark pointer />
                  <Text subtitle size="m">
                    Documentation
                  </Text>
                </ClickableArea>
                <ClickableArea
                  p={{ y: 1.5, x: 2 }}
                  as="a"
                  href={LINKS.api_ref.root}
                  target="_blank"
                  rel="noreferrer"
                  flex={{ align: 'center' }}
                  rounded>
                  <Icon icon="api" left dark pointer />
                  <Text subtitle size="m">
                    API Reference
                  </Text>
                </ClickableArea>
                <StyledBeamerButton
                  p={{ y: 1.5, x: 2 }}
                  flex={{ align: 'center' }}
                  rounded
                  className="beamerTrigger">
                  <Icon icon="new_releases" left pointer />
                  <Text subtitle size="m">
                    What's new
                  </Text>
                </StyledBeamerButton>
                <ClickableArea
                  p={{ y: 1.5, x: 2 }}
                  flex={{ align: 'center' }}
                  rounded
                  onClick={showChat}>
                  <Icon left icon="chat" pointer />
                  <Text subtitle size="m">
                    Live Chat
                  </Text>
                </ClickableArea>
                <ClickableArea
                  p={{ y: 1.5, x: 2 }}
                  flex={{ align: 'center' }}
                  rounded
                  as="a"
                  href={LINKS.community}
                  target="_blank"
                  rel="noreferrer">
                  <Icon icon="slack_colored" left pointer />
                  <Text subtitle size="m">
                    Join Developer Slack
                  </Text>
                </ClickableArea>
              </Div>
              <Divider />
              <Div p={1}>
                <ClickableArea
                  onClick={() => {
                    toggle(false);
                    handleLogout(HookdeckAPI);
                  }}
                  p={{ y: 1.5, x: 2 }}
                  rounded
                  flex={{ align: 'center' }}>
                  <Icon icon={'logout'} left={2} pointer />
                  <Text subtitle>Logout</Text>
                </ClickableArea>
              </Div>
            </>
          )}
        </Dropdown>
      </StyledNavLinks>
    </StyledDashboardNavSection>
  );
};

export default NavFooter;
